/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */
jQuery.fn.exists = function(){return this.length>0;};
(function ($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Pinnacle = {
        // All pages
        'common': {
            init: function () {
                // JavaScript to be fired on all pages
                setTimeout(function () {
                    $('body, html').scrollTop(0);
                    $('.load-overlay').velocity('fadeOut');
                }, 100);
                $(".liquid").imgLiquid();
            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        // Notice that the class 'page-home' becomes 'page_home'
        'page_home': {
            init: function () {
                // JavaScript to be fired on the home page
                //console.log("It works!");
                // jQuery.fn.exists = function(){return this.length>0;}
                $('body').addClass('my-body-is-ready');
                if ($('body').hasClass('page-home')) {
                    setup_homepage();
                    setup_serviceworker();
                //    setup_drag();
                }

            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Pinnacle;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

function setup_homepage() {
    var max_container_height = 100;
    $.each($('main.main .post-list article a.article-link'), function (index, value) {
        //// max height
        if ($(value).closest('article').height() > max_container_height) {
            max_container_height = $(value).closest('article').height();
        }


        ///// Load all data directly
        var this_url = $(value).closest('a.article-link').attr('href');
        this_url = this_url.replace('/blog/post/', '/blog/ajax-post/');
        if (!$.trim($(value).closest('article').find('.extra-info').html()).length) {
            $.get(this_url, function (data) {
                // load
            }).done(function (data) {
                $(value).closest('article').find('.extra-info').html(data);
            });
        }

        //// Use Ajax on click
        $(value).on('click', function (e) {
            "use strict";
            e.stopPropagation();
            e.preventDefault();
            var this_url = $(e.target).closest('a.article-link').attr('href');
            this_url = this_url.replace('/blog/post/', '/blog/ajax-post/');
            if (!$.trim($(e.target).closest('article').find('.extra-info').html()).length) {
                $.get(this_url, function (data) {
                    // load
                }).done(function (data) {
                    $(e.target).closest('article').find('.extra-info').html(data);
                    $(e.target).closest('article').addClass('flip');
                    $('.post-list').addClass('one-flipped');
                    setTimeout(function () {
                        $('.post-list').addClass('height-changed');
                    }, 0);
                    setTimeout(function () {
                        $(e.target).closest('article').addClass('width-change');
                    }, 0);
                });
            } else {
                $(e.target).closest('article').addClass('flip');
                $('.post-list').addClass('one-flipped');
                setTimeout(function () {
                    $('.post-list').addClass('height-changed');
                }, 0);
                setTimeout(function () {
                    $(e.target).closest('article').addClass('width-change');
                }, 0);

            }
            setTimeout(function () {
                setup_drag();
            }, 500);
        });
    });

    $('.close-item').on('click', function (e) {
        $(e.target).closest('article').removeClass('flip');
        $('.flip-container').animate({ scrollTop: 0 }, "slow");
        $('.post-list').removeClass('one-flipped');
        $('.post-list').removeClass('height-changed');
    });


    // add flip function
    $('main.main .post-list article').addClass('flip-container'); //.css('height', max_container_height + 'px');


    // fill remainer items
    var items_in_row = 5;
    var list_count = $('main.main .post-list article').length;
    var items_remainer = items_in_row - (list_count % items_in_row);
    for (var i = 0; i < items_remainer; i++) {
        $('main.main .post-list').append('<article class="empty-article"></article>');
    }


    // load other links
    $('body').on('click', 'a:not(.article-link)', function (e) {
        "use strict";
        e.stopPropagation();
        e.preventDefault();
        var this_url = $(e.target).closest('a').attr('href');
        $.get(this_url, function (data) {
            // load
        }).done(function (data) {
            $('#level-3 .inner').html(data);
            $('#level-3').addClass('open');

        });
    });
    $('body').on('click', 'button.close', function (e) {
        $('#level-3').removeClass('open');
    });
}


function setup_serviceworker() {
    /**
     * SERVICE WORKERS
     */
    if ('serviceWorker' in navigator) {
        window.addEventListener('load', function() {
            navigator.serviceWorker.register('/sw.js').then(function(registration) {
                // Registration was successful
                console.log('ServiceWorker registration successful with scope: ', registration.scope);
            }, function(err) {
                // registration failed :(
                console.log('ServiceWorker registration failed: ', err);
            });
        });
    }
}

var initScale = 1;
var ticking = false;
var transform;
var timer;


window.START_X = 0;
window.START_Y = 0;
window.el = document.querySelector(".flip");
window.direction = 1;
window.perv_text = document.querySelector(".prev-el span").innerHTML;
window.next_text = document.querySelector(".next-el span").innerHTML;

function reset_widths() {
    if ($('.flip').exists()) {
        window.el = document.querySelector(".flip");
        var el = window.el;
        var screen = document.querySelector(".post-list");
        window.START_X = Math.round((screen.offsetWidth - el.offsetWidth) / 2);
        window.START_Y = Math.round((screen.offsetHeight - el.offsetHeight) / 2);
    }
}

var el = document.querySelector(".flip");

function setup_drag() {
    "use strict";
    reset_widths();
    var el = window.el;

    var mc = new Hammer.Manager(el, {
        touchAction: "pan-y"
    });
    mc.add(new Hammer.Pan({ threshold: 0, pointers: 0 }));
    mc.add(new Hammer.Swipe()).recognizeWith(mc.get('pan'));
  //  mc.add(new Hammer.Rotate({ threshold: 0 })).recognizeWith(mc.get('pan'));
 //   mc.add(new Hammer.Pinch({ threshold: 0 })).recognizeWith([mc.get('pan'), mc.get('rotate')]);

  //  mc.add(new Hammer.Tap({ event: 'doubletap', taps: 2 }));
  //  mc.add(new Hammer.Tap());
    mc.on("panstart panmove horizontal", onPan);
    mc.on("hammer.input", function(ev) {
        if(ev.isFinal) {
            resetElement();
        }
    });
    el.classList.add = 'animate';


    transform = {
        translate: { x: window.START_X, y: window.START_Y },
        scale: 1,
        angle: 0,
        rx: 0,
        ry: 0,
        rz: 0
    };

    // var myElement = document.getElementById('testelement');
    //
    // // create a simple instance
    // // by default, it only adds horizontal recognizers
    // var mc = new Hammer(myElement);
    //
    // // listen to events...
    // mc.on("panleft panright tap press", function(ev) {
    //     myElement.textContent = ev.type +" gesture detected.";
    // });
}

var reqAnimationFrame = (function () {
    return window[Hammer.prefixed(window, 'requestAnimationFrame')] || function (callback) {
            window.setTimeout(callback, 1000 / 60);
        };
})();

function logEvent(ev) {
    var el = window.el;
   // console.log(ev);
   // el.innerText = ev.type;
}

function resetElement() {
    reset_widths();
    var el = window.el;
    el.classList.add = 'animate';
    transform = {
        translate: { x: 0, y: 0 }//,
        //scale: 1,
        //angle: 0,
        //rx: 0,
        //ry: 0,
        //rz: 0
    };
    var this_element = $('.flip-container.flip');
    $('.next-el').removeClass('view');
    $('.prev-el').removeClass('view');
    if (window.direction === 2) {
        //next

        //$(this_element).removeClass('flip');
        $('.close-item', this_element).click();
        if ($(this_element).next('.flip-container').exists()) {
            setTimeout(function () {
                $(this_element).next('.flip-container').find('.article-link').click();
            }, 600);
        }
    }
    if (window.direction === 4) {
        //prev
        //$(this_element).removeClass('flip');
        $('.close-item', this_element).click();
        if ($(this_element).prev('.flip-container').exists()) {
            setTimeout(function () {
                $(this_element).prev('.flip-container').find('.article-link').click();
            }, 600);
        }
    }

    window.direction = 1;
    requestElementUpdate();
}

function updateElementTransform() {
    reset_widths();
    var el = window.el;
    var value = [
        'translate3d(' + transform.translate.x + 'px, ' + 0 + 'px, 0)'//,
        //'translate3d(' + transform.translate.x + 'px, ' + transform.translate.y + 'px, 0)'//,
        //'scale(' + transform.scale + ', ' + transform.scale + ')',
        //'rotate3d('+ transform.rx +','+ transform.ry +','+ transform.rz +','+  transform.angle + 'deg)'
    ];

    value = value.join(" ");
    el.style.webkitTransform = value;
    el.style.mozTransform = value;
    el.style.transform = value;
    ticking = false;
}

function requestElementUpdate() {
    if(!ticking) {
        reqAnimationFrame(updateElementTransform);
        ticking = true;
    }
}

function onPan(ev) {
    reset_widths();
    var el = window.el;
    if ($('.flip').exists()) {
        el.classList.remove = 'transform';
        document.querySelector(".prev-el span").innerHTML = window.perv_text;
        document.querySelector(".next-el span").innerHTML = window.next_text;
        document.querySelector(".prev-el").classList.remove('close');
        document.querySelector(".next-el").classList.remove('close');

        if (!$('.flip').prev('.flip-container').exists()) {
            document.querySelector(".prev-el span").innerHTML = 'x';
            document.querySelector(".prev-el").classList.add('close');
        }
        if (!$('.flip').next('.flip-container').exists()) {
            document.querySelector(".next-el span").innerHTML = 'x';
            document.querySelector(".next-el").classList.add('close');
        }
        if (ev.direction === 2 && ev.deltaX < -300) {
            window.direction = 2; // drag right
            $('.next-el').addClass('view');
        } else if (ev.direction === 4 && ev.deltaX > 300) {
            window.direction = 4; // drag left
            $('.prev-el').addClass('view');
        } else {
            window.direction = 1;
            $('.next-el').removeClass('view');
            $('.prev-el').removeClass('view');
        }

        transform.translate = {
            x: window.START_X + ev.deltaX,
            y: window.START_Y + ev.deltaY
        };
    }

    logEvent(ev);
    requestElementUpdate();
}


function onPinch(ev) {
    var el = window.el;
    if(ev.type == 'pinchstart') {
        initScale = transform.scale || 1;
    }

    el.className = '';
    transform.scale = initScale * ev.scale;

    logEvent(ev);
    requestElementUpdate();
}

var initAngle = 0;
function onRotate(ev) {
    var el = window.el;
    if(ev.type == 'rotatestart') {
        initAngle = transform.angle || 0;
    }

    el.className = '';
    transform.rz = 1;
    transform.angle = initAngle + ev.rotation;

    logEvent(ev);
    requestElementUpdate();
}

function onSwipe(ev) {
    reset_widths();
    var el = window.el;
    var angle = 50;
    transform.ry = (ev.direction & Hammer.DIRECTION_HORIZONTAL) ? 1 : 0;
    transform.rx = (ev.direction & Hammer.DIRECTION_VERTICAL) ? 1 : 0;
    transform.angle = (ev.direction & (Hammer.DIRECTION_RIGHT | Hammer.DIRECTION_UP)) ? angle : -angle;

    clearTimeout(timer);
    timer = setTimeout(function () {
        resetElement();
    }, 300);

    logEvent(ev);
    requestElementUpdate();
}

function onTap(ev) {
    var el = window.el;
    transform.rx = 1;
    transform.angle = 25;

    clearTimeout(timer);
    timer = setTimeout(function () {
        resetElement();
    }, 200);

    logEvent(ev);
    requestElementUpdate();
}

function onDoubleTap(ev) {
    reset_widths();
    var el = window.el;
    transform.rx = 1;
    transform.angle = 80;

    clearTimeout(timer);
    timer = setTimeout(function () {
        resetElement();
    }, 500);

    logEvent(ev);
    requestElementUpdate();
}
